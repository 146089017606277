import { ABTesting } from './edgeConfig'
import { trimSlash } from '@/src/lib/str'

export type Variation = {
  main: string
  variationUrl: string
  variation: number
}

export const getAbVariation = (
  uri: string,
  configuration: ABTesting[] | undefined
): Variation | null => {
  if (!configuration) {
    return null
  }

  uri = trimSlash(uri)
  const currentTest = configuration.find(
    (test) =>
      trimSlash(test.main_page.url) === uri ||
      test.pages.find((page) => trimSlash(page.url) === uri)
  )

  if (!currentTest) {
    return null
  }
  const variations = [currentTest.main_page, ...currentTest.pages]

  const variationIndex = variations.findIndex(
    (page) => trimSlash(page.url) === uri
  )
  return {
    main: currentTest.main_page.url,
    variationUrl: variations[variationIndex].url,
    variation: variationIndex,
  }
}

export const canonical = (
  fullUrl: string,
  variation?: Pick<Variation, 'main' | 'variationUrl'>
) => {
  if (!variation) {
    return fullUrl
  }
  return fullUrl.replace(variation.variationUrl, variation.main)
}
