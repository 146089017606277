import { useSessionStorage } from 'usehooks-ts'

export type UtmData = {
  utmTerm: string | undefined
  utmSource: string | undefined
  utmMedium: string | undefined
  ref: string | undefined
}

export default function useUtmCache(): {
  utmData: UtmData
  setUtmParam: (data: Partial<{ [Property in keyof UtmData]: string }>) => void
} {
  const [utmData, setUtmData] = useSessionStorage<UtmData>('utm_data', {
    utmTerm: undefined,
    utmSource: undefined,
    utmMedium: undefined,
    ref: undefined,
  })

  const setUtmParam = (
    data: Partial<{ [Property in keyof UtmData]: string }>
  ) => {
    setUtmData((prev) => ({ ...prev, ...data }))
  }

  return { utmData, setUtmParam }
}
