import { type ClassValue, clsx } from 'clsx'
import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

const breakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
}

export default function useBreakpointReached(
  breakpoint: keyof typeof breakpoints
): boolean {
  const [breakPointReached, setBreakpointReached] = useState(false)

  useEffect(() => {
    setBreakpointReached(window.innerWidth >= breakpoints[breakpoint])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return breakPointReached
}
