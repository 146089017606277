import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { Variation } from '@/src/lib/abTesting'

export default function useTrackingData(
  variation: Variation | null | undefined
) {
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.info('Tag manager not initialized in development mode')
      return
    }
    if (!process.env.NEXT_PUBLIC_GTM_ID) {
      // eslint-disable-next-line no-console
      console.warn('Tag manager id not set')
      return
    }

    TagManager.initialize({
      gtmId: process.env.NEXT_PUBLIC_GTM_ID,
      // @ts-expect-error: Custom property
      customUrl: process.env.NEXT_PUBLIC_GTM_URL,
      dataLayer: {
        variant: variation?.variation
          ? getVariationNameFromNumber(variation.variation)
          : undefined,
      },
    })
  }, [variation])
}

const getVariationNameFromNumber = (variation: number) => {
  return String.fromCharCode(variation + 65) // 0 + 65 = A, 1 + 65 = B, etc.
}
