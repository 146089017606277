import camelCase from 'lodash-es/camelCase'
import upperFirst from 'lodash-es/upperFirst'

export function pascalCase(snakeCaseString: string): string {
  return upperFirst(camelCase(snakeCaseString))
}

export function ensureTrailingSlash(url: string): string {
  return url.endsWith('/') ? url : `${url}/`
}

export function trimSlash(url: string): string {
  return url.replace(/^\/|\/$/g, '')
}
