import '../css/globals.css'
import 'dayjs/locale/de-ch'
import { Analytics } from '@vercel/analytics/react'
import { SpeedInsights } from '@vercel/speed-insights/next'
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import useUtmCache, { UtmData } from '../hooks/useUTMCache'
import type { AppProps } from 'next/app'
import { TooltipProvider } from '@/src/components/core/Tooltip'
import useTrackingData from '@/src/hooks/useTrackingData'
import { canonical } from '@/src/lib/abTesting'

function MyApp({ Component, pageProps }: AppProps) {
  useTrackingData(pageProps.variation)
  dayjs.locale('de-ch')
  dayjs.extend(weekday)

  const { setUtmParam } = useUtmCache()
  const router = useRouter()

  useEffect(() => {
    const utmParams: Partial<{ [Property in keyof UtmData]: string }> = {}
    if (router.query.utm_term) {
      utmParams.utmTerm = router.query.utm_term as string
    }
    if (router.query.utm_source) {
      utmParams.utmSource = router.query.utm_source as string
    }
    if (router.query.utm_medium) {
      utmParams.utmMedium = router.query.utm_medium as string
    }
    if (router.query.ref) {
      utmParams.ref = router.query.ref as string
    }
    setUtmParam(utmParams)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router])

  return (
    <>
      <Head>
        <meta name="charset" content="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="msapplication-square310x310logo"
          content="/icons/mstile-310x310.png"
        />
        <title key="title">BLINK Fahrschule Schweiz</title>
        <link
          data-n-head="ssr"
          rel="apple-touch-icon"
          sizes="180x180"
          href="/icons/apple-touch-icon-180x180.png"
        />
        <link
          data-n-head="ssr"
          rel="shortcut icon"
          type="image/x-icon"
          href="/icons/favicon-32x32.ico"
        />
        <link
          data-n-head="ssr"
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/icons/favicon-32x32.ico"
        />
      </Head>
      <div className="overflow-x-hidden font-sans font-medium text-blue-900 antialiased">
        <TooltipProvider delayDuration={100}>
          <Component
            {...pageProps}
            fullUrl={canonical(pageProps.fullUrl || '', pageProps.variation)}
          />
        </TooltipProvider>
      </div>
      <Analytics />
      <SpeedInsights />
    </>
  )
}

export default MyApp
